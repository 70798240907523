import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '../components/Common/PageTitle';
import StandortEntry from '../components/Standorte/StandortEntry';

const StandortePage = ({ data, location }) => {
  const { siteMetadata } = data.site;
  const { standorte } = data;
  const { title, icon, metadata, opengraph } = data.page;

  return (
    <DefaultLayout>
      <div className="wrap">
        <PageTitle title={title} icon={icon} />
        {standorte.edges.map(({ node }, index) => (
          <StandortEntry entry={node} imagePosition={index % 2 === 0 ? 'left' : 'right'} key={node.id} />
        ))}
        <Metadata
          data={metadata}
          opengraphData={opengraph}
          defaultTitle={siteMetadata.title}
          canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
          path={location.pathname}
        />
      </div>
    </DefaultLayout>
  );
};

export default StandortePage;

export const StandortePageQuery = graphql`
  query StandortPageQuery($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionStandorte(lang: { eq: $language }) {
      title
      icon
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    standorte: allCockpitStandort(filter: { lang: { eq: $language } }) {
      edges {
        node {
          id
          title
          name
          description
          image_alt
          postcode
          city
          street
          zip
          tel
          fax
          email
          www
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100)
            }
          }
        }
      }
    }
  }
`;
