// extracted by mini-css-extract-plugin
export var addressBox = "StandortEntry-module--addressBox--SISMw";
export var anchor = "StandortEntry-module--anchor--c7P8Q";
export var contactBox = "StandortEntry-module--contactBox--Qzr0c";
export var content = "StandortEntry-module--content--cwYbm";
export var image_left = "StandortEntry-module--image_left--zQWk1";
export var image_right = "StandortEntry-module--image_right--TkEMw";
export var text_left = "StandortEntry-module--text_left--d6dOQ";
export var text_right = "StandortEntry-module--text_right--WhYYn";
export var title = "StandortEntry-module--title--SXD+D";
export var vcard = "StandortEntry-module--vcard--OyaSj";