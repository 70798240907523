import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import clsx from 'clsx';

import * as styles from './StandortEntry.module.css';

function StandortEntry({ entry, imagePosition }) {
  const { title, name, description, street, postcode, city, tel, fax, email, www, image, image_alt } = entry;
  const { t } = useTranslation();
  const cleanedWwwUrl = www ? www.replace('https://', '').replace('http://', '').replace('/', '') : null;

  return (
    <div style={{ position: 'relative' }}>
      <span id={`plz-${postcode}`} className={styles.anchor} />
      <div className={styles.content}>
        <div className={styles[`image_${imagePosition}`]}>
          <h3>{title}</h3>
          <GatsbyImage image={getImage(image)} alt={image_alt} />
        </div>

        <div className={styles[`text_${imagePosition}`]}>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
          <div className={clsx(styles.vcard, 'vcard')}>
            <div className={clsx(styles.title, 'fn', 'org')}>{name}</div>
            <div className="adr">
              <div className={styles.addressBox}>
                <div className="street-address">{street}</div>
                <span className="postal-code">{postcode}</span>
                &nbsp;<span className="locality">{city}</span>
                <div className="country-name">{t('Austria')}</div>
              </div>
              <div className={styles.contactBox}>
                {tel && <div className="">Tel.: {tel}</div>}
                {fax && <div className="">Fax: {fax}</div>}
                {email && (
                  <div className="">
                    <a href={`mailto:${email}`}>{email}</a>
                  </div>
                )}
                {www && (
                  <div>
                    <a href={www} target="_blank">
                      {cleanedWwwUrl}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StandortEntry;
